<template>
  <div v-if="readOnly">
    <div :class="preview ? 'w-full' : 'w-3/4'">
      <div v-if="items.length === 0">
        <div class="bg-orange-100 rounded py-2 px-4 opacity-80">
          {{$t('schema.evidenceTable.label.noTablesAdded')}}
        </div>
      </div>
      <div v-else>
        <ul>
          <li v-for="(item, index) in items" :key="$uuid(index)">
            <EvidenceTableAccordion :model="item"
                                    :section="section"
                                    :properties="properties"
                                    :readOnly="readOnly" />
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div v-else class="flex" :id="id">
    <div class="w-full pr-0">
      <div v-if="items.length === 0">
        <div v-show="!isSaving" class="bg-orange-100 rounded py-2 px-4 opacity-80 mb-4">
          {{$t('schema.evidenceTable.label.noTablesAdded')}}
        </div>
        <ul>
          <li>
            <div v-show="isSaving" class="w-full flex justify-center items-center">
              <font-awesome-icon icon="spinner" class="animate-spin text-charcoalgray text-hlg" />
            </div>
          </li>
        </ul>
      </div>
      <div v-else>
        <ul>
          <li v-for="(item, index) in items" :key="$uuid(index)">
            <EvidenceTableAccordion :model="item"
                                    :section="section"
                                    :properties="properties"
                                    :readOnly="readOnly"
                                    @get-schema="this.$emit('getSchema');" />
          </li>
          <li>
            <div v-show="isSaving" class="w-full flex justify-center items-center">
              <font-awesome-icon icon="spinner" class="animate-spin text-charcoalgray text-hlg" />
            </div>
          </li>
        </ul>
      </div>
      <div v-if="showNew" class="p-4 mb-4 border-2 bg-bluegray-300">
        <form @submit.prevent="save">
          <table class="w-full">
            <!-- Overskrift -->
            <tr v-show="section === 'KnowledgeSummary'">
              <td colspan="2" class="p-4 text-center">
                <span v-show="section === 'KnowledgeSummary'" class="text-xl font-medium">
                  {{$t('schema.evidenceTable.header.descriptionKnowledgeSummary')}}
                </span>
                <span v-show="section === 'PrimaryStudy'" class="text-xl font-medium">
                  {{$t('schema.evidenceTable.header.descriptionPrimaryStudy')}}
                </span>
              </td>
            </tr>
            <!-- Kort-ID -->
            <tr>
              <td class="w-1/4 align-top">
                <label class="text-sm" for="shortId">
                  {{$t('schema.evidenceTable.label.shortId')}}
                </label>
              </td>
              <td class="w-3/4">
                <input type="text"
                       id="shortId"
                       ref="evidenceTableShortId"
                       v-model="item.shortId"
                       :placeholder="$t('schema.evidenceTable.placeholder.shortId')"
                       required />
              </td>
            </tr>
            <!-- Referanse -->
            <tr>
              <td class="w-1/4 align-top">
                <label class="text-sm" for="reference">
                  {{$t('schema.evidenceTable.label.reference')}}
                </label>
              </td>
              <td class="w-3/4">
                <ResizableTextArea :name="reference"
                                   :placeholder="$t('schema.evidenceTable.placeholder.reference')"
                                   :readOnly="false"
                                   v-model="item.reference" />
              </td>
            </tr>
            <!-- Primærstudier -->
            <tr v-show="section === 'KnowledgeSummary'">
              <td class="w-1/4 align-top">
                <label class="text-sm" for="numberOfPrimaryStudies">
                  {{$t('schema.evidenceTable.label.numberOfPrimaryStudies')}}
                </label>
              </td>
              <td class="w-3/4">
                <input type="text" id="numberOfPrimaryStudies"
                       v-model="item.numberOfPrimaryStudies"
                       :placeholder="$t('schema.evidenceTable.placeholder.numberOfPrimaryStudies')" />
              </td>
            </tr>
            <!-- Land -->
            <tr v-show="section === 'PrimaryStudy'">
              <td class="w-1/4 align-top">
                <label class="text-sm" for="country">
                  {{$t('schema.evidenceTable.label.country')}}
                </label>
              </td>
              <td class="w-3/4">
                <input type="text"
                       id="country"
                       v-model="item.country" />
              </td>
            </tr>
            <!-- Studiedesign -->
            <tr v-show="section === 'PrimaryStudy'">
              <td class="w-1/4 align-top">
                <label class="text-sm" for="studyDesign">
                  {{$t('schema.evidenceTable.label.studyDesignShort')}}
                </label>
              </td>
              <td class="w-3/4">
                <ResizableTextArea :name="studyDesign"
                                   :readOnly="false"
                                   v-model="item.studyDesign" />
              </td>
            </tr>
            <tr v-if="section === 'KnowledgeSummary'">
              <td class="w-1/4 align-top">
                <label class="text-sm" for="studyDesign">
                  {{$t('schema.evidenceTable.label.studyDesign')}}
                </label>
              </td>
              <td class="w-3/4">
                <ResizableTextArea :name="studyDesign"
                                   :placeholder="$t('schema.evidenceTable.placeholder.studyDesign')"
                                   :readOnly="false"
                                   v-model="item.studyDesign" />
              </td>
            </tr>
            <!-- Dato -->
            <tr v-show="section === 'KnowledgeSummary'">
              <td class="w-1/4 align-top">
                <label class="text-sm" for="date">
                  {{$t('schema.evidenceTable.label.date')}}
                </label>
              </td>
              <td class="w-3/4">
                <input type="text"
                        id="date"
                        v-model="item.date"
                        :placeholder="$t('schema.evidenceTable.placeholder.date')"/>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <hr class="my-2" />
              </td>
            </tr>
            <!-- Populatsjon -->
            <tr>
              <td class="w-1/4 align-top">
                <label class="text-sm font-medium" for="population">
                  {{$t('schema.evidenceTable.label.population')}}
                </label>
              </td>
              <td class="w-3/4">
                <ResizableTextArea :name="population"
                                   :placeholder="$t('schema.evidenceTable.placeholder.population')"
                                   :readOnly="false"
                                   v-model="item.population" />
              </td>
            </tr>
            <tr v-show="section === 'KnowledgeSummary'">
              <td class="w-1/4 align-top">
                <label class="text-sm" for="populationNumberOfPatiens">
                  {{$t('schema.evidenceTable.label.numberOfPatientsPopulation')}}
                </label>
              </td>
              <td class="w-3/4">
                <input type="text"
                       id="populationNumberOfPatiens"
                       v-model="item.populationNumberOfPatiens"
                       :placeholder="$t('schema.evidenceTable.placeholder.populationNumberOfPatiens')" />
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <hr class="my-2" />
              </td>
            </tr>
            <!-- Intervensjonsgruppe -->
            <tr>
              <td colspan="2">
                <p class="font-semibold">{{$t('schema.evidenceTable.header.interventionGroup')}}</p>
              </td>
            </tr>
            <tr>
              <td class="w-1/4 align-top">
                <label class="text-sm" for="interventionGroup">
                  {{$t('schema.evidenceTable.label.methodsInterventionGroup')}}
                </label>
              </td>
              <td class="w-3/4">
                <ResizableTextArea :name="interventionGroup"
                                   :placeholder="$t('schema.evidenceTable.placeholder.methodsInterventionGroup')"
                                   :readOnly="false"
                                   v-model="item.interventionGroup" />
              </td>
            </tr>
            <tr>
              <td class="w-1/4 align-top">
                <label class="text-sm" for="interventionGroupNumberOfPatiens">
                  {{$t('schema.evidenceTable.label.numberOfPatientsInterventionGroup')}}
                </label>
              </td>
              <td class="w-3/4">
                <input type="text"
                       id="interventionGroupNumberOfPatiens"
                       v-model="item.interventionGroupNumberOfPatiens"
                       :placeholder="$t('schema.evidenceTable.placeholder.numberOfPatientsInterventionGroup')"
                       />
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <hr class="my-2" />
              </td>
            </tr>
            <!-- Kontrollgruppe -->
            <tr>
              <td colspan="2">
                <p class="font-semibold">
                  {{$t('schema.evidenceTable.header.controlGroup')}}
                </p>
              </td>
            </tr>
            <tr>
              <td class="w-1/4 align-top">
                <label class="text-sm" for="controlGroup">
                  {{$t('schema.evidenceTable.label.methodsControlGroup')}}
                </label>
              </td>
              <td class="w-3/4">
                <ResizableTextArea :name="controlGroup"
                                   :placeholder="$t('schema.evidenceTable.placeholder.methodsControlGroup')"
                                   :readOnly="false"
                                   v-model="item.controlGroup" />
              </td>
            </tr>
            <tr>
              <td class="w-1/4 align-top">
                <label class="text-sm" for="controlGroupNumberOfPatiens">
                  {{$t('schema.evidenceTable.label.numberOfPatientsControllCroup')}}
                </label>
              </td>
              <td class="w-3/4">
                <input type="text"
                       id="controlGroupNumberOfPatiens"
                       v-model="item.controlGroupNumberOfPatiens"
                       :placeholder="$t('schema.evidenceTable.placeholder.numberOfPatientsControllCroup')"
                       />
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <hr class="my-2" />
              </td>
            </tr>
            <!-- Utfallsmål -->
            <tr>
              <td class="w-1/4 align-top">
                <div class="flex flex-row w-full align-top">
                  <label class="text-sm font-semibold" for="outcomeGoals">
                    {{$t('schema.evidenceTable.header.outcomeGoals')}}
                    <Help v-if="properties" :model="properties.outcomeHelp" />
                  </label>
                </div>
              </td>
              <td class="w-3/4">
                <ResizableTextArea v-if="section === 'PrimaryStudy'"
                                   :name="outcomeGoals"
                                   :placeholder="$t('schema.evidenceTable.placeholder.outcomeGoalsPrimary')"
                                   :readOnly="false"
                                   v-model="item.outcomeGoals" />
                <ResizableTextArea v-show="section === 'KnowledgeSummary'"
                                   :name="outcomeGoals"
                                   :placeholder="$t('schema.evidenceTable.placeholder.outcomeGoalsKnowledge')"
                                   :readOnly="false"
                                   v-model="item.outcomeGoals" />
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <hr class="my-2" />
              </td>
            </tr>
            <!-- Effekt og sikkerhet -->
            <tr>
              <td colspan="2" class="p-4 text-center">
                <span class="text-xl font-medium">
                  {{$t('schema.evidenceTable.header.results')}}
                </span>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <label class="text-sm font-semibold" for="resultEffect">
                  {{$t('schema.evidenceTable.label.effect')}}
                  <Help v-if="properties" :model="properties.effectHelp" />
                </label>
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <Editor v-model='item.resultEffect'
                       :api-key='getTinyKey'
                       :init='getTinyConfig' />
              </td>
            </tr>
            <tr>
              <td colspan="2">
                <hr class="my-2" />
              </td>
            </tr>
            <!-- Annet -->
            <tr>
              <td colspan="2" class="p-4 text-center">
                <span class="text-xl font-medium">
                  {{$t('schema.evidenceTable.header.other')}}
                </span>
              </td>
            </tr>
            <tr v-show="section === 'PrimaryStudy'">
              <td class="w-1/4 align-top">
                <label class="text-sm" for="finance">
                  {{$t('schema.evidenceTable.label.finance')}}
                </label>
              </td>
              <td class="w-3/4">
                <ResizableTextArea :name="finance"
                                   :placeholder="$t('schema.evidenceTable.placeholder.finance')"
                                   :readOnly="false"
                                   v-model="item.finance" />
              </td>
            </tr>
            <tr v-show="section === 'PrimaryStudy'">
              <td class="w-1/4 align-top">
                <div class="flex flex-row w-full align-top">
                  <label class="text-sm font-semibold" for="risk">
                    {{$t('schema.evidenceTable.label.risk')}}
                    <Help v-if="properties" :model="properties.riskHelp" />
                  </label>
                </div>
              </td>
              <td class="w-3/4">
                <select v-model="item.risk" @change.prevent="onSelectedRiskChange($event)">
                  <option value="Lav risiko">Lav risiko</option>
                  <option value="Uklar risiko">Uklar risiko</option>
                  <option value="Høy risiko">Høy risiko</option>
                  <option value="Ikke vurdert">Ikke vurdert</option>
                </select>
              </td>
            </tr>
            <tr v-show="section === 'KnowledgeSummary'">
              <td class="w-1/4 align-top">
                <div class="flex flex-row w-full align-top">
                  <label class="text-sm font-semibold" for="methodQuality">
                    {{$t('schema.evidenceTable.label.methodQuality')}}
                    <Help v-if="properties" :model="properties.methodQualityHelp" />
                  </label>
                </div>
              </td>
              <td class="w-3/4">
                <select v-model="item.methodQuality" @change.prevent="onSelectedQualityChange($event)">
                  <option value="Høy">Høy</option>
                  <option value="Middels">Middels</option>
                  <option value="Lav">Lav</option>
                </select>
              </td>
            </tr>
            <tr>
              <td class="w-1/4 align-top">
                <label class="text-sm" for="methodQualityComments">
                  {{$t('schema.common.comments.anyComments')}}
                </label>
              </td>
              <td class="w-3/4">
                <ResizableTextArea :name="methodQualityComments"
                                   :readOnly="false"
                                   v-model="item.methodQualityComments" />
              </td>
            </tr>
          </table>
          <div class="flex">
            <button type="submit" v-show="!isSaving" class="mr-4 btn btn--small btn--charcoalgray">
              <font-awesome-icon icon="save" class="mr-2" />
              {{$t('schema.common.action.save')}}
            </button>
            <button type="reset" @click.prevent="cancel" class="btn btn--small btn--charcoalgray">
              <font-awesome-icon icon="ban" class="mr-2" />
              {{$t('schema.common.action.cancel')}}
            </button>
          </div>
        </form>
      </div>
      <button @click.prevent="addNew" v-show="!showNew" class="btn btn--full btn--secondary__outline" ref="evidenceTableAddNew">
        <font-awesome-icon icon="plus" class="mr-2" />
        {{$t('schema.evidenceTable.addEvidenceTable')}}
      </button>
    </div>
  </div>
</template>

<script>
  import Editor from '@tinymce/tinymce-vue';
  import TinyConfig from '@/assets/scripts/tinyConfig';
  import EvidenceTableAccordion from '@/views/components/schema/evidenceTable/EvidenceTableAccordion.vue';
  import Help from '@/views/components/help/Help.vue';
  import ResizableTextArea from '@/views/components/textarea/ResizableTextArea.vue';

  export default {
    props: {
      items: {
        type: Object,
        required: false,
      },
      properties: {
        type: Object,
        required: false,
      },
      // PrimaryStudy or KnowledgeSummary
      section: {
        type: String,
        required: false,
      },
      contentId: {
        type: Number,
        required: false,
      },
      readOnly: {
        type: Boolean,
        required: false,
      },
      preview: {
        type: Boolean,
        required: false,
      },
    },
    components: {
      EvidenceTableAccordion,
      Help,
      ResizableTextArea,
      Editor,
    },
    computed: {
      getTinyConfig() {
        return TinyConfig.getConfig(this.type);
      },
      getTinyKey() {
        return TinyConfig.getAPIKey();
      },
    },
    data() {
      return {
        id: this.$uuid(),
        showNew: false,
        isSaving: false,
        item: {
          type: 'custom',
          customType: 'evidenceTable',
          reference: '',
          shortId: '',
          date: '',
          numberOfPrimaryStudies: '',
          studyDesign: '',
          population: '',
          populationNumberOfPatiens: '',
          interventionGroup: '',
          interventionGroupNumberOfPatiens: '',
          controlGroup: '',
          controlGroupNumberOfPatiens: '',
          outcomeGoals: '',
          methodQuality: '',
          methodQualityComments: '',
          resultEffect: '',
          finance: '',
          risk: '',
          country: '',
        },
        comments: null,
        contentGuid: '',
      };
    },
    methods: {
      goto() {
        const el = document.getElementById(this.id);
        el.scrollIntoView({ behavior: 'smooth' });
      },
      getSchema() {
        this.$emit('getSchema');
      },
      onSelectedRiskChange(event) {
        this.item.risk = event.target.value;
      },
      onSelectedQualityChange(event) {
        this.item.methodQuality = event.target.value;
      },
      setResultEffect(input) {
        this.item.resultEffect = input;
      },
      save() {
        this.isSaving = true;
        const requestOptions = {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            id: this.contentId,
            section: this.section,
            reference: this.item.reference,
            shortId: this.item.shortId,
            dateAsString: this.item.date,
            numberOfPrimaryStudies: this.item.numberOfPrimaryStudies,
            studyDesign: this.item.studyDesign,
            population: this.item.population,
            populationNumberOfPatiens: this.item.populationNumberOfPatiens,
            interventionGroup: this.item.interventionGroup,
            interventionGroupNumberOfPatiens: this.item.interventionGroupNumberOfPatiens,
            controlGroup: this.item.controlGroup,
            controlGroupNumberOfPatiens: this.item.controlGroupNumberOfPatiens,
            outcomeGoals: this.item.outcomeGoals,
            methodQuality: this.item.methodQuality,
            methodQualityComments: this.item.methodQualityComments,
            resultEffect: this.item.resultEffect,
            risk: this.item.risk,
            finance: this.item.finance,
            country: this.item.country,
          }),
        };
        fetch(`${window.location.origin}/api/schema/CreateEvidenceTableItem`, requestOptions)
          .then(() => {
            setTimeout(() => {
              this.showNew = false;
              this.goto();
              this.$emit('getSchema');
              this.$nextTick(() => {
                this.$refs.evidenceTableAddNew.focus();
                this.isSaving = false;
                this.$toast.open({
                  message: 'Evidenstabell lagret',
                  type: 'success',
                  dismissible: true,
                });
              });
            }, 1000);
          })
          .catch((error) => {
            console.log(error);
            this.$emit('getSchema');
          });
      },
      addNew() {
        this.showNew = true;
        this.clear();
        this.$nextTick(() => { this.$refs.evidenceTableShortId.focus(); });
      },
      cancel() {
        this.showNew = false;
        this.clear();
        this.$nextTick(() => { this.$refs.evidenceTableAddNew.focus(); });
      },
      clear() {
        this.item.reference = '';
        this.item.shortId = '';
        this.item.date = '';
        this.item.numberOfPrimaryStudies = '';
        this.item.studyDesign = '';
        this.item.population = '';
        this.item.populationNumberOfPatiens = '';
        this.item.interventionGroup = '';
        this.item.interventionGroupNumberOfPatiens = '';
        this.item.controlGroup = '';
        this.item.controlGroupNumberOfPatiens = '';
        this.item.outcomeGoals = '';
        this.item.methodQuality = '';
        this.item.methodQualityComments = '';
        this.item.resultEffect = '';
        this.item.finance = '';
        this.item.risk = '';
        this.item.country = '';
      },
    },
    emits: ['getSchema'],
  };
</script>
